<template>
    <div class="conversation-settings">
        <section class="settings">
            <span class="title-content">
                <b-container fluid>
                    <b-row class="mb-3">
                        <b-col cols="10 text-left">
                            <h1>Definir características da conversa</h1>
                        </b-col>
                        <b-col cols="2 text-right">
                            <button class="start-button" @click="sendData">Start Speaking</button>
                        </b-col>
                    </b-row>
                </b-container>
            </span>

            <b-container fluid>
                <b-card class="settings-card">
                    <b-container fluid>
                        <!-- Titulo do chat -->
                        <b-row class="mb-3 text-left">
                            <b-col cols="3">
                                <label for="interaction-category"><strong>Chat title:</strong></label>
                            </b-col>
                            <b-col cols="9">
                                <input v-model="chatName" type="text" id="cefr-level" class="form-control"
                                    placeholder="Set a name for your chat" />
                            </b-col>
                        </b-row>
                        <!-- Categoria de interação -->
                        <b-row class="mb-3 text-left">
                            <b-col cols="3">
                                <label for="interaction-category"><strong>Oral interaction category:</strong></label>
                            </b-col>
                            <b-col cols="9">
                                <b-select v-model="selectedInteractionCategory" :options="interactionCategories"
                                    @change="onCategoryChange" class="select-options">
                                </b-select>
                            </b-col>
                        </b-row>

                        <!-- Nível CEFR -->
                        <b-row class="mb-3 text-left">
                            <b-col cols="3">
                                <label for="cefr-level"><strong>CEFR Level:</strong></label>
                            </b-col>
                            <b-col cols="9">
                                <b-select v-model="selectedCEFRLevel" :options="cefrLevels" class="select-options">
                                </b-select>
                            </b-col>
                        </b-row>

                        <!-- Campo de seleção múltipla para alunos -->
                        <b-row class="mb-3 text-left">
                            <b-col cols="3">
                                <label for="student-selection"><strong>Select Students:</strong></label>
                            </b-col>
                            <b-col cols="9">
                                <b-select v-model="selectedStudents" :options="studentsOptions" class="select-options"
                                    @change="addTag">
                                </b-select>
                            </b-col>
                        </b-row>
                        <b-row class="mb-3 text-left">
                            <b-col cols="9">
                                <!-- Componente BFormTag para exibir as tags -->
                                <b-form-tag v-for="(tag, index) in selectedTags" :key="index" @remove="removeTag(tag)"
                                    :title="tag">
                                    {{ tag.text }}
                                </b-form-tag>
                            </b-col>
                        </b-row>

                        <!-- Cenário e checkbox "up to you" -->
                        <b-row class="mb-3 text-left">
                            <b-col cols="3">
                                <label for="scenario"><strong>Scenario:</strong></label>
                            </b-col>
                            <b-col cols="6" class="text-left">
                                <label class="custom-checkbox">
                                    <input type="checkbox" v-model="isUpToYouChecked" @change="fetchScenario" />
                                    <span class="custom-checkmark"></span>
                                    <strong>up to you (a IA irá criar o cenário automaticamente)</strong>
                                </label>
                            </b-col>
                            <input v-model="scenarioText" type="text" id="cefr-level" class="form-control"
                                placeholder="Example: Planning a Trip: Discussing and arranging details for a holiday or day trip with friends." />
                        </b-row>
                    </b-container>
                </b-card>
            </b-container>
        </section>
    </div>
</template>

<script>
/* eslint-disable */
import { api } from '@/services'

export default {
    data() {
        return {
            selectedInteractionCategory: '',
            selectedCEFRLevel: '',
            interactionCategories: [],
            cefrLevels: [],
            scenarioText: '',
            isUpToYouChecked: false,
            autoGeneratedScenario: '',
            selectedStudents: [],
            selectedTags: [],
            studentsOptions: [],
            chatName: ''
        };
    },
    methods: {
        addTag(value) {
            // Busca o objeto de opção completo usando o value
            const selectedStudent = this.studentsOptions.find(option => option.value === value);

            // Adiciona o texto da opção selecionada como uma tag se ainda não estiver na lista
            if (selectedStudent && !this.selectedTags.some(tag => tag.value === value)) {
                this.selectedTags.push(selectedStudent); // Adiciona o objeto inteiro { value, text }
            }
            console.log(this.selectedTags)
        },
        removeTag(tag) {
            // Remove a tag da lista
            this.selectedTags = this.selectedTags.filter(item => item !== tag);
        },
        async fetchData() {
            try {
                const response = await api.get('/conversation-settings');
                this.interactionCategories = response.data.interactionCategories;
                this.cefrLevels = response.data.cefrLevels;
            } catch (error) {
                console.error('Erro ao buscar dados da API:', error);
            }
        },
        async fetchStudents() {
            try {
                const response = await api.get('/getStudentsBySchool');
                this.studentsOptions = response.data.map(student => ({
                    value: student.id,  // ID do aluno
                    text: student.name  // Nome do aluno
                }));
            } catch (error) {
                console.error('Erro ao buscar dados dos alunos:', error);
            }
        },
        // Método para gerar cenário automaticamente
        async fetchScenario() {
            if (this.isUpToYouChecked) {
                try {
                    const response = await api.get('/generate-scenario', {
                        category: this.selectedInteractionCategory,
                        level: this.selectedCEFRLevel
                    });
                    this.autoGeneratedScenario = response.data.scenario;
                    this.scenarioText = this.autoGeneratedScenario;  // Preenche o campo de cenário
                } catch (error) {
                    console.error('Erro ao gerar cenário:', error);
                }
            } else {
                this.autoGeneratedScenario = '';
                this.scenarioText = '';
            }
        },
        async sendData() {
            const user_ids = [];
            this.selectedTags.forEach(tag => {
                user_ids.push(tag.value);
            });

            const payload = {
                chat_name: this.chatName,
                oral_interaction: this.selectedInteractionCategory,
                cefr_level: this.selectedCEFRLevel,
                scenario: this.scenarioText,
                user_ids: user_ids,
            }

            console.log("Enviando dados:", payload);

            try {
                const response = await api.post('/setchatconfig', payload);
                console.log("Dados salvos com sucesso:", response.data);

                if (response.status === 200) {
                    this.$router.push('/conversation'); 
                }
            } catch (error) {
                console.error("Erro ao salvar dados:", error);
                
            }
        }
    },
    mounted() {
        this.fetchData();
        this.fetchStudents();
    }
};
</script>

<style scoped>
.conversation-settings {
    max-width: 75%;
    margin: 0 auto;
    border-radius: 5px;
    color: black
}

.form-control {
    border: none;
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.363);
}

.title-content {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
}

.settings-card {
    padding: 20px;
    background-color: #EEFAFC;
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.363);
    display: flex;
    flex-direction: row;
}

.scenario-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.start-button {
    background-color: #002d62;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.options-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 1%;
}

.select-otions {
    max-width: 60%;
    border: none;
    background-color: #EEFAFC;
}

.scenario {
    display: flex;
    flex-direction: row;
}

.check-box-text {
    margin-left: 7px;
}
</style>

<style scoped>
.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkmark {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid #ccc;
    margin-right: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.custom-checkbox input:checked+.custom-checkmark {
    background-color: green;
    border-color: green;
}

.custom-checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked+.custom-checkmark::after {
    display: block;
}

.custom-checkbox .custom-checkmark::after {
    left: 5px;
    top: 0.5px;
    width: 6px;
    height: 12px;
    border: solid rgb(255, 255, 255);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
</style>